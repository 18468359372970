import shelves1 from "../../assets/images/sideview.jpg"
import shelves2 from "../../assets/images/shelf.jpg"
import shelves3 from "../../assets/images/book5.jpg"

export const shelvesData = [
  {
    img: shelves1,
    alt: "shelves",
    name: "Pick your covers",
  },
  {
    img: shelves2,
    alt: "shelves",
    name: "Pick your type",
  },
  {
    img: shelves3,
    alt: "shelves",
    name: "Pick your size",
  },
]
